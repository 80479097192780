body {
  overflow: hidden;
}

.leadName {
  position: relative;
}
.leadName div {
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  text-transform: capitalize;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
             supported by Chrome, Edge, Opera and Firefox */
}
.leadName svg {
  position: absolute;
  right: 1rem;
  top: 12px;
}

.shown {
  transition: 1s;
  border-radius: 0px 0px 10px 10px;
  background-color: #F7FBFC;
}
.shown td:first-child {
  border-radius: 10px 0 10px 0;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}
.shown td {
  border-top: 1px solid #e5e5e5 !important;
}
.shown td:last-child {
  border-radius: 0 10px 0 10px;
  border-right: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

.leadContent td {
  padding: 0;
  border: 1px solid #e5e5e5 !important;
  background: #F7FBFC;
  border-radius: 0 0 10px 10px;
}
.leadContent .inner {
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.leadContent .inner .leadInfo, .leadContent .inner .clientInfo {
  border: 1px solid #e5e5e5;
  width: 40%;
  min-height: 16rem;
  overflow: hidden;
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
}
.leadContent .inner .leadInfo div {
  flex-direction: column;
  display: flex;
  height: 72%;
  justify-content: space-between;
}
.leadContent .inner .leadInfo textarea {
  resize: none;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 10px;
  background: transparent;
  height: 98%;
  font-size: 0.875rem;
  line-height: 20px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.leadContent .inner .clientInfo div {
  display: flex;
  justify-content: space-between;
  text-transform: lowercase;
}
.leadContent .inner .clientInfo div span:first-letter {
  text-transform: uppercase;
}
.leadContent .inner .clientInfo div button {
  padding: 0;
  display: flex;
  background: transparent !important;
  justify-content: flex-end;
}

.status {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: white;
  opacity: 0.7;
  margin: 0;
}

/*# sourceMappingURL=main.module.css.map */
