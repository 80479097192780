.scoreTable {
  max-width: 91%;
  overflow: hidden;
}
.scoreTable thead, .scoreTable tbody {
  display: flex;
  flex-direction: column-reverse !important;
}
.scoreTable tr {
  display: flex;
  flex-direction: row;
}

.action {
  display: flex !important;
  justify-content: space-between;
  flex-direction: row;
}
.action div {
  display: flex;
  line-height: 19px;
}

.completed {
  opacity: 0.5;
  pointer-events: none;
}

.danger, .completedMsg, .warning {
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 5px;
  color: white;
  font-size: 10px;
}

.name {
  justify-content: space-between;
}

.danger {
  background: #f44336;
}

.warning {
  background: #ff9800;
}

.completedMsg {
  background: green;
}

#scoreGraph {
  width: 50%;
}

/*# sourceMappingURL=main.module.css.map */
