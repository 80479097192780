.tabNavigator {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.tabNavigator a {
  width: 33%;
  margin: 0 1%;
  border-radius: 9px;
  padding: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
}
.tabNavigator a:hover {
  background: rgba(255, 255, 255, 0.2);
}
.tabNavigator .active {
  background: rgba(255, 255, 255, 0.2);
}

/*# sourceMappingURL=main.module.css.map */
